/**
 * Theme: Hyper - Responsive Bootstrap 5 Admin Dashboard
 * Author: Coderthemes
 * Module/App: Layout Js
 */

/**
 * LeftSidebar
 * @param {*} $
 */

!(function ($) {
  'use strict'

  var LeftSidebar = function () {
    ;(this.body = $('body')), (this.window = $(window))
  }

  /**
   * Reset the theme
   */
  ;(LeftSidebar.prototype._reset = function () {
    this.body.removeAttr('data-leftbar-theme')
  }),
    /**
     * Activates the condensed side bar
     */
    (LeftSidebar.prototype.activateCondensedSidebar = function () {
      this.body.attr('data-leftbar-compact-mode', 'condensed')
    }),
    /**
     * Deactivates the condensed side bar
     */
    (LeftSidebar.prototype.deactivateCondensedSidebar = function () {
      this.body.removeAttr('data-leftbar-compact-mode')
    }),
    /**
     * Initilizes the menu
     */
    (LeftSidebar.prototype.initMenu = function () {
      var self = this

      // resets everything
      this._reset()

      // click events
      // Left menu collapse
      $(document).on('click', '.button-menu-mobile', function (e) {
        e.preventDefault()
        self.body.toggleClass('sidebar-enable')

        if (self.window.width() >= 576) {
          if (self.body.attr('data-leftbar-compact-mode') === 'condensed') {
            self.deactivateCondensedSidebar()
          } else {
            self.activateCondensedSidebar()
          }
        }
      })

      // sidebar - main menu
      if ($('.side-nav').length) {
        var navCollapse = $('.side-nav li .collapse')
        var navToggle = $(".side-nav li [data-bs-toggle='collapse']")
        navToggle.on('click', function (e) {
          return false
        })

        // open one menu at a time only
        navCollapse.on({
          'show.bs.collapse': function (event) {
            var parent = $(event.target).parents('.collapse.show')
            $('.side-nav .collapse.show').not(event.target).not(parent).collapse('hide')
          },
        })

        // activate the menu in left side bar (Vertical Menu) based on url
        $('.side-nav a').each(function () {
          var pageUrl = window.location.href.split(/[?#]/)[0]
          if (this.href == pageUrl) {
            $(this).addClass('active')
            $(this).parent().addClass('menuitem-active')
            $(this).parent().parent().parent().addClass('show')
            $(this).parent().parent().parent().parent().addClass('menuitem-active') // add active to li of the current link

            var firstLevelParent = $(this).parent().parent().parent().parent().parent().parent()
            if (firstLevelParent.attr('id') !== 'sidebar-menu') firstLevelParent.addClass('show')

            $(this).parent().parent().parent().parent().parent().parent().parent().addClass('menuitem-active')

            var secondLevelParent = $(this)
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
            if (secondLevelParent.attr('id') !== 'wrapper') secondLevelParent.addClass('show')

            var upperLevelParent = $(this)
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
            if (!upperLevelParent.is('body')) upperLevelParent.addClass('menuitem-active')
          }
        })
      }

      //Horizontal Menu (For SM Screen)
      var AllNavs = document.querySelectorAll('ul.navbar-nav .dropdown .dropdown-toggle')

      var isInner = false

      AllNavs.forEach(function (element) {
        element.addEventListener('click', function (event) {
          if (!element.parentElement.classList.contains('nav-item')) {
            isInner = true
            //element.parentElement.parentElement.classList.add('show');
            var parent = element.parentElement.parentElement.parentElement.querySelector('.nav-link')
            bootstrap.Dropdown.getInstance(parent).show()
            if (element.ariaExpanded) {
              bootstrap.Dropdown.getInstance(element).hide()
            } else {
              bootstrap.Dropdown.getInstance(parent).show()
            }
            isInner = true
          }
        })

        element.addEventListener('hide.bs.dropdown', function (event) {
          if (isInner) {
            event.preventDefault()
            event.stopPropagation()
            isInner = false
          }
        })

        element.addEventListener('show.bs.dropdown', function (event) {
          if (!isInner && !element.parentElement.classList.contains('nav-item')) {
            event.preventDefault()
            event.stopPropagation()
            isInner = true
          }
        })
      })
    }),
    /**
     * Initilizes the menu
     */
    (LeftSidebar.prototype.init = function () {
      this.initMenu()
    }),
    ($.LeftSidebar = new LeftSidebar()),
    ($.LeftSidebar.Constructor = LeftSidebar)
})(window.jQuery),
  /**
   * Topbar
   * @param {*} $
   */
  (function ($) {
    'use strict'

    var Topbar = function () {
      ;(this.$body = $('body')), (this.$window = $(window))
    }

    /**
     * Initilizes the menu
     */
    ;(Topbar.prototype.initMenu = function () {
      if ($('.topnav-menu').length) {
        $('.topnav-menu li a').each(function () {
          var pageUrl = window.location.href.split(/[?#]/)[0]
          if (this.href == pageUrl) {
            $(this).addClass('active')
            $(this).parent().parent().addClass('active') // add active to li of the current link
            $(this).parent().parent().parent().parent().addClass('active')
            $(this).parent().parent().parent().parent().parent().parent().addClass('active')
          }
        })

        // Topbar - main menu
        $('.navbar-toggle').on('click', function () {
          $(this).toggleClass('open')
          $('#navigation').slideToggle(400)
        })
      }
    }),
      /**
       * Initilizes the menu
       */
      (Topbar.prototype.init = function () {
        this.initMenu()
      }),
      ($.Topbar = new Topbar()),
      ($.Topbar.Constructor = Topbar)
  })(window.jQuery),
  /**
   * RightBar
   * @param {*} $
   */
  (function ($) {
    'use strict'

    var RightBar = function () {
      ;(this.body = $('body')), (this.window = $(window))
    }

    /**
     * Select the option based on saved config
     */
    ;(RightBar.prototype._selectOptionsFromConfig = function () {
      var config = $.App.getLayoutConfig()
      if (config) {
        $('.end-bar input[type=checkbox]').prop('checked', false)

        $('#condensed-check').prop('checked', config.isCondensed)
        $('#fixed-check').prop('checked', !config.isCondensed)
      }
    }),
      /**
       * Initilizes the right side bar
       */
      (RightBar.prototype.init = function () {
        var self = this

        $(document).on('click', 'body', function (e) {
          if ($(e.target).closest('.end-bar-toggle, .end-bar').length > 0) {
            return
          }

          if (
            $(e.target).closest('.leftside-menu, .side-nav').length > 0 ||
            $(e.target).hasClass('button-menu-mobile') ||
            $(e.target).closest('.button-menu-mobile').length > 0
          ) {
            return
          }
          $('body').removeClass('end-bar-enabled')
          $('body').removeClass('sidebar-enable')
          return
        })

        // compact
        $('input[type=checkbox][name=compact]').change(function () {
          switch ($(this).val()) {
            case 'fixed':
              $.App.deactivateCondensedSidebar()
              break
            case 'condensed':
              $.App.activateCondensedSidebar()
              break
          }
          self._selectOptionsFromConfig()
        })

        // reset
        $('#resetBtn').on('click', function (e) {
          e.preventDefault()
          // reset to default
          $.App.resetLayout(function () {
            self._selectOptionsFromConfig()
          })
        })
      }),
      ($.RightBar = new RightBar()),
      ($.RightBar.Constructor = RightBar)
  })(window.jQuery),
  /**
   * Layout and theme manager
   * @param {*} $
   */

  (function ($) {
    'use strict'

    var DEFAULT_CONFIG = {
      isCondensed: false,
    }

    var LayoutThemeApp = function () {
      ;(this.body = $('body')), (this.window = $(window)), (this._config = {})
      this.defaultSelectedStyle = null
    }

    /**
     * Preserves the config
     */
    ;(LayoutThemeApp.prototype._saveConfig = function (newConfig) {
      $.extend(this._config, newConfig)
      // sessionStorage.setItem('_HYPER_CONFIG_', JSON.stringify(this._config));
    }),
      /**
       * Get the stored config
       */
      (LayoutThemeApp.prototype._getStoredConfig = function () {
        var bodyConfig = this.body.data('layoutConfig')
        var config = DEFAULT_CONFIG
        if (bodyConfig) {
          config['isCondensed'] = bodyConfig['leftSidebarCondensed']
        }
        return config
      }),
      /**
       * Apply the given config and sets the layout and theme
       */
      (LayoutThemeApp.prototype._applyConfig = function () {
        var self = this

        // getting the saved config if available
        this._config = this._getStoredConfig()

        // activate menus
        $.LeftSidebar.init()

        // sets condensed view
        if (self._config.isCondensed) self.activateCondensedSidebar()
      }),
      /**
       * Initilizes the layout
       */
      (LayoutThemeApp.prototype._adjustLayout = function () {
        // in case of small size, add class enlarge to have minimal menu
        if (this.window.width() >= 750 && this.window.width() <= 1028) {
          this.activateCondensedSidebar(true)
        } else {
          var config = this._getStoredConfig()
          if (!config.isCondensed && !config.isScrollable) this.deactivateCondensedSidebar()
        }
      }),
      /**
       * Activates the condensed side bar
       */
      (LayoutThemeApp.prototype.activateCondensedSidebar = function (ignoreToStore) {
        if (!ignoreToStore) {
          this._saveConfig({
            isCondensed: true,
            isScrollable: false,
          })
        }
        $.LeftSidebar.activateCondensedSidebar()
      }),
      /**
       * Deactivates the condensed side bar
       */
      (LayoutThemeApp.prototype.deactivateCondensedSidebar = function () {
        this._saveConfig({ isCondensed: false })
        $.LeftSidebar.deactivateCondensedSidebar()
      })

    /**
     * Clear out the saved config
     */
    ;(LayoutThemeApp.prototype.clearSavedConfig = function () {
      this._config = DEFAULT_CONFIG
    }),
      /**
       * Gets the config
       */
      (LayoutThemeApp.prototype.getConfig = function () {
        return this._config
      }),
      /**
       * Reset to default
       */
      (LayoutThemeApp.prototype.reset = function (callback) {
        this.clearSavedConfig()

        var self = this
        if ($('#main-style-container').length) {
          self.defaultSelectedStyle = $('#main-style-container').attr('href')
        }
        // calling the call back to let the caller know that it's done
        callback()
      }),
      /**
       *
       */
      (LayoutThemeApp.prototype.init = function () {
        var self = this

        if ($('#main-style-container').length) {
          self.defaultSelectedStyle = $('#main-style-container').attr('href')
        }

        // initilize the menu
        this._applyConfig()

        // adjust layout based on width
        this._adjustLayout()

        // on window resize, make menu flipped automatically
        this.window.on('resize', function (e) {
          e.preventDefault()
          self._adjustLayout()
        })

        // topbar
        $.Topbar.init()
      }),
      ($.LayoutThemeApp = new LayoutThemeApp()),
      ($.LayoutThemeApp.Constructor = LayoutThemeApp)
  })(window.jQuery)
